<template>
    <div style="border-top: 1px solid #dfdfdf;margin-bottom: 30px">
        <CollegeList v-for="(item,i) in hotSchool" :key="i" :item="item" />
    </div>
</template>

<script>
    import CollegeList from "../../../components/CollegeList";
    //time: 2020/10/24
    export default {
        name: "HotCollege",
        components: {
            CollegeList
        },
        data() {
            return {
                hotSchool:[]
            }
        },
        created() {
            this.$fecth
                .get('school/getHotSchool', { params: { id: this.$route.query.cid }})
                .then((res) => {
                    this.hotSchool = res.list
                })
        },
        methods: {
            
        }
    }

</script>

<style lang="less" scoped>

</style>