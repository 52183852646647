var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news"},[_c('div',{staticClass:"left"},[_c('ul',_vm._l((_vm.newsList),function(item,i){return _c('li',{key:i},[_c('el-row',{staticClass:"line"},[_c('el-col',{staticClass:"title_n",attrs:{"span":12}},[_c('router-link',{attrs:{"to":{
            path: 'solution',
            name: 'solution',
            query: {
              nid: item.id,
              cid: _vm.cid,
              sid: _vm.sid,
              degree: _vm.degree,
            },
          }}},[_vm._v(_vm._s(item.title))])],1),_c('el-col',{staticClass:"time",attrs:{"span":3,"offset":9}},[_vm._v(_vm._s(item.add_time.split(" ")[0]))])],1)],1)}),0)]),_c('div',{staticClass:"right",staticStyle:{"overflow":"hidden"}},[_c('img',{staticStyle:{"height":"220px","width":"316px"},attrs:{"src":_vm.news_thumb,"alt":""}})])])}
var staticRenderFns = []

export { render, staticRenderFns }