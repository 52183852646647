<template>
  <div>
    <div v-if="degree == 1">
      <div :span="8">
        <p class="rank-title">大学排名</p>
        <div class="ranks">
          <div>
            <p>{{ schoolData.ranking }}</p>
            <p>院校排名</p>
          </div>
          <div>
            <p>{{ schoolData.star }}</p>
            <p>院校星级</p>
          </div>
          <!-- <div>
            <p>{{ schoolData.city_ranking }}</p>
            <p>省内排名</p>
          </div> -->
          <div>
            <p>{{ schoolData.type_ranking }}</p>
            <p>类型排名</p>
          </div>
        </div>
        <ul class="brief">
          <li>
            院校排名：该院校在全国高职高专院校综合竞争力的排名情况（前800名）。
          </li>
          <li>院校星级：按5★+、5★、5★-、4★、3★、2★、1★排列。</li>
          <!-- <li>省内排名：该院校在本省高职高专院校的排名情况。</li> -->
          <li>
            类型排名：该院校在所属类型的排名；高职高专院校分八种类型：综合类、师范类、理工类、农林类、医药类、财经类、文法类、艺体类。
          </li>
          <li>引用中国科教评价网</li>
        </ul>
      </div>
    </div>
    <div v-else>
      <div class="ranks_1">
        <div>
          <p>{{ rankingData.wulianshu }}</p>
          <p>武连书</p>
        </div>
        <div>
          <p>{{ rankingData.ruanke }}</p>
          <p>软科</p>
        </div>
        <div>
          <p>{{ rankingData.xiaoyouhui }}</p>
          <p>校友会</p>
        </div>
        <div>
          <p>{{ rankingData.qs }}</p>
          <p>QS</p>
        </div>
        <div>
          <p>{{ rankingData.us }}</p>
          <p>U.S.News</p>
        </div>
      </div>
      <ul class="brief">
        <li>武书连排名是由武书连编写的关于中国大陆的大学排名</li>
        <li>
          软科排名是由上海软科教育信息咨询有限公司（简称软科）发布的中国大陆大学排名
        </li>
        <li>校友会排名艾瑞深中国校友会网发布的中国大陆大学排名</li>
        <li>
          QS排名是由英国公司Quacquarelli
          Symonds（简称QS，中文名夸夸雷利·西蒙兹公司）所发表的中国大陆大学排名
        </li>
        <li>
          U.S.News排名，是由美国权威的《美国新闻与世界报道》
          发布的中国大陆大学排名
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ranking",
  data() {
    return {
      degree: 1,
      schoolData: {
        city_name: "暂无",
        city_ranking: "暂无",
        id: "暂无",
        name: "暂无",
        ranking: "暂无",
        school_id: "暂无",
        star: "暂无",
        type: "暂无",
        type_ranking: "暂无",
      },
      rankingData: {
        wulianshu: "暂无",
        ruanke: "暂无",
        xiaoyouhui: "暂无",
        qs: "暂无",
        us: "暂无",
      },
    };
  },
  created() {
    this.degree = this.$route.query.degree;
    this.$fecth
      .post("school_ranking/getDataBySchool", {
        degree: this.degree,
        school_id: this.$route.query.cid,
      })
      .then((res) => {
        if (res) {
          if (this.degree == 1) {
            this.schoolData = res;
          } else {
            this.rankingData = res;
          }
        }
      });
  },

};
</script>

<style scoped lang='less'>
.ranks {
  >div {
    display: inline-block;
    width: 20%;
    text-align: center;
    padding: 0 5px;

    p:first-child {
      font-size: 16px;
      font-weight: bold;
      border: 1px dotted #999;
      border-bottom: none;
      padding: 15px 0;
    }

    p:last-child {
      font-size: 14px;
      background-color: #fef3f0;
      color: #fd847d;
      padding: 2px 0;
    }
  }
}

.ranks_1 {
  >div {
    display: inline-block;
    width: 17%;
    text-align: center;
    padding: 0 5px;

    p:first-child {
      font-size: 16px;
      font-weight: bold;
      border: 1px dotted #999;
      border-bottom: none;
      padding: 15px 0;
    }

    p:last-child {
      font-size: 14px;
      background-color: #fef3f0;
      color: #fd847d;
      padding: 2px 0;
    }
  }
}

.brief {
  margin-top: 2px;
  color: gray;
  font-size: 8px;
  width: 90%;
  margin-left: 10px;
}
</style>
