<template>
  <div class="main">
    <div class="title">基本信息</div>
    <div class="intro">
      <div class="logo">
        <img class="school_img" :src="this.$parent.$data.school.school_img" /> >
      </div>
      <div class="txt">
        <span>{{ this.$parent.$data.school.desc }}</span>
        <div @click="test()" style="
            position: absolute;
            right: 1px;
            bottom: -24px;
            color: #03a9f49e;
          ">
          查看更多
        </div>
      </div>
    </div>
    <div class="title">
      基本数据
      <span class="title_rank"> 大学排名</span>
    </div>
    <div class="info">
      <div class="info_data">
        <div>
          <p v-if="this.$parent.$data.school.found_time">
            {{ this.$parent.$data.school.found_time }}
          </p>
          <p v-else>暂无</p>
          <p>创建时间</p>
          <p class="line"></p>
        </div>
        <div>
          <p v-if="this.$parent.$data.school.area">
            {{ this.$parent.$data.school.area }}
          </p>
          <p v-else>暂无</p>
          <p>占地面积</p>
          <p class="line"></p>
        </div>
        <div>
          <p v-if="this.$parent.$data.school.student">
            {{ this.$parent.$data.school.student }}人
          </p>
          <p v-else>暂无</p>
          <p>在校生</p>
          <p class="line"></p>
        </div>
        <div>
          <p v-if="this.$parent.$data.school.teacher">
            {{ this.$parent.$data.school.teacher }}人
          </p>
          <p v-else>暂无</p>
          <p>教职工</p>
          <p class="line"></p>
        </div>
        <div>
          <p v-if="this.$parent.$data.school.school_belong">
            {{ this.$parent.$data.school.school_belong }}
          </p>
          <p v-else>暂无</p>
          <p>主管部门</p>
        </div>
        <div>
          <p v-if="this.$parent.$data.school.batch">
            <span style="none" v-if="this.$parent.$data.school.batch.split(',', 1) == 1">本科一批</span>
            <span v-if="this.$parent.$data.school.batch.split(',', 1) == 2">本科二批</span>
            <span v-if="this.$parent.$data.school.batch.split(',', 1) == 3">高职高专批</span>
          </p>
          <p v-else>暂无</p>
          <p>招生批次</p>
        </div>
        <div>
          <p v-if="this.$parent.$data.school.school_id">
            {{ this.$parent.$data.school.school_id }}
          </p>
          <p v-else>暂无</p>
          <p>院校代号</p>
        </div>
        <div>
          <p v-if="this.$parent.$data.school.fees">
            {{ this.$parent.$data.school.fees }}元
          </p>
          <p v-else>暂无</p>
          <p>最低住宿费</p>
        </div>
      </div>
    </div>
    <div class="rank">
      <Ranking />
    </div>
    <div class="title">选科匹配专业</div>
    <xuanke :name="$parent.$data.school.name"></xuanke>
    <div class="title">招生章程</div>
    <News></News>

    <div class="title" v-if="this.$parent.$data.school.lqyq">2024录取要求</div>
    <div class="specialty_brief" v-if="this.$parent.$data.school.lqyq">
      {{ this.$parent.$data.school.lqyq }}
      <p class="beizhu" style="">备注：以学校当年公布的招生章程为准</p>
    </div>
    <div class="title" v-if="this.$parent.$data.school.specialty_brief">优势专业</div>
    <div class="specialty_brief" v-if="this.$parent.$data.school.specialty_brief">
      {{ this.$parent.$data.school.specialty_brief }}
    </div>
    <div class="title">院系专业</div>
    <Academy></Academy>

    <Interdisciplinary></Interdisciplinary>
    <div class="title">其他院校</div>
    <HotCollege></HotCollege>

    <el-drawer title="院校详情" :visible.sync="drawer" :with-header="false">
      <div class="xiangqing" v-html="this.desc"></div>
    </el-drawer>
  </div>
</template>

<script>
import News from "./components/News";
import Academy from "./components/Academy";
import HotCollege from "./components/HotCollege";
import Ranking from "./components/Ranking";
import Interdisciplinary from "./components/Interdisciplinary";
import xuanke from "./components/xuanke";

export default {
  name: "Info",
  components: {
    News,
    Academy,
    HotCollege,
    Ranking,
    Interdisciplinary,
    xuanke
  },
  data() {
    return {
      desc: "",
      drawer: false,
    };
  },
  methods: {
    test() {
      this.drawer = true;

      this.$fecth
        .post("school/schoolDesc", {
          school_id: this.$route.query.cid,
        })
        .then((res) => {
          this.desc = res;
        });
    },
  },
};
</script>

<style lang="less" scoped>
@keyframes rainbow {
  0% {
    left: 0;
  }

  50% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

.main {
  .title {
    border-left: 5px solid #519cea;
    text-indent: 0.5rem;
    margin: 20px 0;
    font-size: 18px;
    color: #4d4d4d;

    .title_rank {
      width: 481px;
      border-left: 5px solid #519cea;
      float: right;
    }
  }

  .intro {
    .logo {
      width: 400px;
      height: 263px;
      padding-right: 30px;
      margin-right: 30px;
      display: inline-block;
      overflow: hidden;

      .school_img {
        position: relative;
        animation: rainbow 44s infinite;
        height: 100%;
      }
    }

    .txt {
      width: 60%;
      line-height: 34px;
      display: inline-block;
      vertical-align: top;
      position: relative;

      span {
        color: #4d4d4d;
        text-indent: 2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
      }
    }
  }

  .info {
    display: inline-block;

    .info_data {
      width: 696px;
      height: 207px;
      background: #257eaf;
      display: flex;
      flex-wrap: wrap;
      text-align: center;

      // div:nth-child(-n + 4) {
      //   // border-bottom: 1px solid #ffffff;
      // }

      .line {
        width: 40px;
        border-bottom: 1px solid #ffffff;
        display: inline-block;
        margin: 0 auto;
        position: relative;
        top: 28px;
      }

      div {
        color: white;
        width: 174px;

        p:first-child {
          font-size: 18px;
          white-space: nowrap;
          font-weight: 600;
          letter-spacing: 1px;
          margin-top: 25px;
        }

        p:last-child {
          margin-top: 5px;
          margin-bottom: 10px;
          font-size: 16px;
          font-weight: 100;
        }
      }
    }
  }

  .rank {
    width: 440px;
    margin-left: 20px;
    padding: 0 20px;
    min-height: 20px;
    display: inline-block;
    vertical-align: top;
  }

  .brief-1 {
    /*list-style: none;*/
    margin-top: 10px;
    margin-left: 5px;
    color: gray;
    font-size: 8px;
  }

  .specialty_brief {
    color: #4d4d4d;
    border: 1px solid #dfdfdf;
    padding: 20px;
    letter-spacing: 1px;
    text-indent: 2rem;
    line-height: 30px;
  }

  .beizhu {
    font-size: 12px;
    color: #999999;
    text-indent: 1px;
  }

  .xiangqing {
    padding: 30px;
  }
}
</style>