<template>
    <div class="academy">
        <el-row class="header">
            <el-col :span="6">学院</el-col>
            <el-col :span="18">所含专业</el-col>
        </el-row>
        <el-row v-for="(item, i) in specialty" :key="i" class="tab-row">
            <el-col :span="6">{{ item.name }}</el-col>
            <el-col :span="18">
                <span v-for="(spec, k) in item.specialty" :key="k">
                    <router-link v-if="spec.specialty_id" :to="'/specialtyInfo?sid=' + spec.specialty_id + '&degree=0'"
                        target="_blank" class="spec">{{ spec.specialty_name }}<span v-if="spec.degree == 1">(专)</span>
                        <span v-if="k != item.specialty.length - 1">、</span>

                    </router-link>
                    <span v-else class="spec">{{ spec.specialty_name }}<span v-if="spec.degree == 1">(专)</span> <span
                            v-if="k != item.specialty.length - 1">、</span></span>
                </span>
            </el-col>
        </el-row>
    </div>
</template>

<script>
//time: 2020/10/24
export default {
    name: "academy",
    data() {
        return {
            specialty: []
        }
    },
    created() {
        this.$fecth
            .get('School/getSchoolSpecialty', {
                params: { id: this.$route.query.cid }
            })
            .then((res) => {
                this.specialty = res.list
            })
    },
    methods: {}
}

</script>

<style scoped lang="less">
.academy {
    border: 1px solid #dfdfdf;
    padding: 20px;
    color: #4d4d4d;

    a {
        color: #4d4d4d;
    }

    .header {
        font-weight: 600;
        margin-bottom: 20px;
        color: black;
    }

    .spec {
        display: inline-block;
        height: 30px;
        line-height: 30px;
        padding-right: 4px;
        vertical-align: top;
    }
}

.tab-row {
    border-bottom: 1px dashed #eeeeff;
}
</style>