<template>
  <div v-if="dataList">
    <div class="title">交叉学科</div>
    <div class="table">
      <el-row class="header">
        <el-col :span="6">交叉学科名称</el-col>
        <el-col :span="18">所涉及一级学科（排名不分先后顺序）</el-col>
      </el-row>
      <el-row v-for="(item, i) in dataList" :key="i" class="tab-row">
        <el-col :span="6">{{ item.specialty_name }}</el-col>
        <el-col :span="18">
          {{ item.child_name }}
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      dataList: null
    };
  },
  created() {
    this.getData()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    getData() {
      this.$fecth
        .post("interdisciplinary/getData", {
          cid: this.$route.query.cid,
        })
        .then((res) => {
          if (res != null) {
            this.dataList = res
          }
        });
    }
  },
};
</script>

<style scoped lang='less'>
.title {
  border-left: 5px solid #519cea;
  text-indent: 0.5rem;
  margin: 20px 0;
  font-size: 18px;
  color: #4d4d4d;
}

.table {
  border: 1px solid #dfdfdf;
  padding: 20px;
  color: #4d4d4d;

  a {
    color: #4d4d4d;
  }

  .header {
    font-weight: 600;
    margin-bottom: 20px;
    color: black;
  }
}

.tab-row {
  border-bottom: 1px dashed #eeeeff;
  line-height: 30px;
}
</style>
