<template>
  <div>

    <div>
      <div class="nav-xuanke">
        <el-tabs >
          <el-tab-pane v-for="(item, key) in subjectList" :key="key" :label="key">
            <div v-for="(val, k) in item" :key="k" class="specialty">
              {{ val.specialty_name }}、
            </div>
          </el-tab-pane>
        </el-tabs>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: '',
  props: {
    name: name
  },
  data() {
    return {
      subjectList: {}
    };
  },
  computed: {
  },
  watch: {
    name(e) {
      this.getPlanNum(e)
    }
  },
  created() {
    if (this.name) {
      this.getPlanNum(this.name)
    }
  },
  methods: {
    getPlanNum(e) {
      this.$fecth
        .post('school/getSpecialtyDemand', {
          name: e,
        })
        .then(res => {
          this.subjectList = res
        })
    }
  },
};
</script>

<style scoped lang='less'>
.specialty{
  display: inline-block;
  margin-right: 1px;
  line-height: 200%;
}
</style>
